import Duration from "@icholy/duration";
import { Container, Table, Text } from "@mantine/core";
import { IconLock } from "@tabler/icons-react";
import {
	eachDayOfInterval,
	endOfMonth,
	format,
	isToday,
	isWeekend,
	startOfMonth,
} from "date-fns";
import { Fragment, useEffect, useState } from "react";
import { getProjects } from "../../api/projects";
import { getRegisteredTimeInPeriodForUser } from "../../api/registered-times";
import {
	getReportLocksForPeriod,
	getReportLocksForUserInPeriod,
} from "../../api/report-locks";
import { getUsers } from "../../api/users";
import type {
	OutputRegisteredTime,
	OutputReportLock,
	Project,
	User,
} from "../../generated";
import { Navigation } from "./navigation";

export const Summary = () => {
	const [userReports, setUserReports] = useState<Array<OutputRegisteredTime>>(
		[],
	);
	const [reportLocks, setReportLocks] = useState<Array<OutputReportLock>>([]);
	const [selectedDate, setSelectedDate] = useState<Date>(new Date());
	const [projects, setProjects] = useState<Array<Project>>([]);
	const [users, setUsers] = useState<Array<User>>([]);

	useEffect(() => {
		getUsers().then(setUsers);
		getProjects().then(setProjects);
	}, []);

	useEffect(() => {
		setUserReports([]);

		const from = startOfMonth(selectedDate);
		const to = endOfMonth(selectedDate);

		Promise.all(
			users.map(user =>
				getRegisteredTimeInPeriodForUser(from, to, user.id),
			),
		).then(r => setUserReports(r.flat()));

		Promise.all(
			users.map(user => getReportLocksForUserInPeriod(user.id, from, to)),
		).then(r => setReportLocks(r.flat()));
	}, [selectedDate, users]);

	const getProjectFromId = (projectId: string) => {
		return projects.find(p => p.id === projectId);
	};

	const getUserFromId = (userId: string) => {
		return users.find(u => u.id === userId);
	};

	const reps = userReports.reduce<{
		[key: string]: {
			[key: string]: { [key: string]: OutputRegisteredTime };
		};
	}>((acc, curr) => {
		if (!acc[curr.user_id]) acc[curr.user_id] = {};

		const date = new Date(curr.date);
		const formattedDate = format(date, "yyyy-MM-dd");

		if (!acc[curr.user_id][curr.project_id])
			acc[curr.user_id][curr.project_id] = {};
		if (!acc[curr.user_id][curr.project_id][formattedDate])
			acc[curr.user_id][curr.project_id][formattedDate] = curr;

		return acc;
	}, {});

	const locks = reportLocks.reduce<{
		[key: string]: Array<string>;
	}>((acc, curr) => {
		if (!acc[curr.user_id]) acc[curr.user_id] = [];

		const date = new Date(curr.date);
		const formattedDate = format(date, "yyyy-MM-dd");

		if (!acc[curr.user_id].includes(formattedDate))
			!acc[curr.user_id].push(formattedDate);

		return acc;
	}, {});

	const daysInMonth = eachDayOfInterval({
		start: startOfMonth(selectedDate),
		end: endOfMonth(selectedDate),
	});

	const cellWidth = 100 / (daysInMonth.length + 1);

	type UserRowProps = {
		user: User | undefined;
	};

	const UserRow = ({ user }: UserRowProps) => {
		if (user !== undefined) {
			return (
				<Table.Tr bg={"vBlue.9"}>
					<Table.Td
						style={{
							border: 0,
							minWidth: "250px",
						}}
					>
						<Text fw={700}>{user.name}</Text>
					</Table.Td>
					{daysInMonth.map(date => {
						const formattedDate = format(date, "yyyy-MM-dd");
						return (
							<Table.Td
								key={date.getTime()}
								style={{
									textAlign: "center",
									border: 0,
									backgroundColor: "transparent",
								}}
							>
								{locks[user.id]?.includes(formattedDate) ? (
									<IconLock size={12} />
								) : null}
							</Table.Td>
						);
					})}
				</Table.Tr>
			);
		}
	};

	return (
		<Container fluid p="xl">
			<Navigation
				selectedDate={selectedDate}
				onChange={setSelectedDate}
			/>
			<Table
				withColumnBorders
				striped={"even"}
				stripedColor={"var(--mantine-color-dark-8)"}
			>
				<Table.Thead>
					<Table.Tr>
						<Table.Th />
						{daysInMonth.map(date => {
							return (
								<Table.Th
									style={{
										textAlign: "center",
										borderBottom: "4px solid transparent",
										background: isWeekend(date)
											? "#402324"
											: "transparent",
										borderColor: isToday(date)
											? "transparent transparent green transparent "
											: "transparent",
										minWidth: "28px",
									}}
									key={date.getTime()}
								>
									{format(date, "d")}
									<span
										style={{
											fontSize: "11px",
											display: "block",
										}}
									>
										{format(date, "E")}
									</span>
								</Table.Th>
							);
						})}
						<Table.Th>Σ</Table.Th>
					</Table.Tr>
				</Table.Thead>
				<Table.Tbody>
					{Object.entries(reps).map(([userId, projects]) => {
						return (
							<Fragment key={userId}>
								<UserRow user={getUserFromId(userId)} />
								{Object.entries(projects).map(
									([projectId, reports]) => {
										const sum = Object.values(
											reports,
										).reduce((acc, curr) => {
											const duration = new Duration(
												curr.time,
											);
											return acc + duration.hours();
										}, 0);

										return (
											<Table.Tr
												key={`${userId}-${projectId}`}
											>
												<Table.Td
													width={`${cellWidth}%`}
												>
													{
														getProjectFromId(
															projectId,
														)?.name
													}
												</Table.Td>
												{daysInMonth.map(date => {
													const formattedDate =
														format(
															date,
															"yyyy-MM-dd",
														);

													if (
														reports[formattedDate]
													) {
														const duration =
															new Duration(
																reports[
																	formattedDate
																].time,
															);

														return (
															<Table.Td
																style={{
																	textAlign:
																		"center",
																	backgroundColor:
																		isWeekend(
																			date,
																		)
																			? "#25262b"
																			: "transparent",
																	color:
																		duration.hours() >
																		0
																			? "var(--mantine-colors-green-0)"
																			: "inherit",
																}}
																width={`${cellWidth}%`}
																key={`${userId}-${projectId}-${formattedDate}`}
															>
																{duration.hours()}
															</Table.Td>
														);
													}

													return (
														<Table.Td
															style={{
																backgroundColor:
																	isWeekend(
																		date,
																	)
																		? "#25262b"
																		: "transparent",
																textAlign:
																	"center",
																color: "var(--mantine-color-dark-4)",
															}}
															width={`${cellWidth}%`}
															key={`${userId}-${projectId}-${formattedDate}`}
														>
															0
														</Table.Td>
													);
												})}
												<Table.Td>{sum}</Table.Td>
											</Table.Tr>
										);
									},
								)}
							</Fragment>
						);
					})}
				</Table.Tbody>
			</Table>
		</Container>
	);
};
